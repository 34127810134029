import React from 'react';
import "./styles.scss";

const TextBlock = ({id = "", headline = false, children}) => {
    const blockHeadline = headline ? <h2 className="section__headline">{headline}</h2> : null;
    return (
        <div className="div-block-21">
            {blockHeadline}
            <div className="subtitle-text">
                {children}
            </div>
        </div>
    );
}

export default TextBlock;