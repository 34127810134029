import React from 'react';

const PageSection = ({id = "", children}) => {
    return (
        <section className="section" id={id}>
            <div className="container">
                {children}
            </div>
        </section>
    );
}

export default PageSection;