import React, {useState} from 'react';
import {ToggleButtons, ToggleButton} from "../layout/toggle-buttons/toggle-buttons";

const SettingsToolbar = ({ ...data }) => {
    const [refsFound, setRefsFound] = useState(true);
    const refs = {};

    const getValidationState = () => {
        const thisValueRE = new RegExp(data.value, 'gi');

        for (const ref in refs) {
            const a = refs[ref].props.value || '';
            if (thisValueRE.test(a) && data.value !== '') {
                setRefsFound(true);
                break;
            }
        }

        if (data.value !== '') {
            if (refsFound && data.value !== '') return 'success';
            if (!refsFound && data.value !== '') return 'error';
        }
    };

    return (
        <div className="settings-toolbar">
            <div className="search-box">
                <input
                    id="SearchInput"
                    type="text"
                    placeholder="Am"
                    {...data}
                />
            </div>
            <ToggleButtons>
                <ToggleButton title="common" active />
                <ToggleButton title="must have" tabIndex="0" />
                <ToggleButton title="advanced" tabIndex="0" />
                <ToggleButton title="all chords" tabIndex="0" />
            </ToggleButtons>
            <ToggleButtons mod="paw">
                <ToggleButton title="righty" iconId="icon-righty" active />
                <ToggleButton title="lefty" iconId="icon-lefty" tabIndex="0" />
            </ToggleButtons>
            {/*<div className="search-feedback">*/}
            {/*    {getValidationState() === 'error' && (*/}
            {/*        <strong>*/}
            {/*            <em>no match found</em>*/}
            {/*        </strong>*/}
            {/*    )}*/}
            {/*</div>*/}
        </div>
    );
};

export default SettingsToolbar;