import React from 'react';

const ContentSection = ({id = "", mod=false, children}) => {
    const modifier = mod ? "content-section--" + mod : "";
    return (
        <div className={`content-section ${modifier}`}>
            {children}
        </div>
    );
}

export default ContentSection;