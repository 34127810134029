import React, {useEffect} from 'react';
import './index.css';
import AppHeader from "./compontents/app-header";
import * as chordsData from './compontents/chord-card/chords-list.json';
import ChordCard from "./compontents/chord-card";
import { initChordResize, initBarre, initFingerHover } from './compontents/chord-card/init-chord';

function componentDidMount() {
    initChordResize();
    initBarre();
    initFingerHover();
};

function ChordPage () {
    useEffect(() => {
        componentDidMount();
    }, []);

    const chordsList =  Array.from(JSON.parse(JSON.stringify(chordsData)));

    const hash = window.location.hash
        .toString()
        .replace("#", "");

    const currChordData = chordsList.find(object => object.Slug === hash);

    const {
        "Full name": fullName,
        "Short name": shortName,
        Slug
    } = currChordData;

    return (
        <div>
            <AppHeader/>
            <div className="content-wrapper">
                <div className="section__headline-centered">
                    <h1 className="headline">{shortName}</h1>
                    <h2 className="subheadline">{fullName}</h2>
                </div>
            </div>
            <ChordCard data={currChordData} large />
            <div className="content-wrapper content-wrapper--centered">
                <div
                    id="w-node-b137bc6a-6ccb-c112-6839-686033c25bd6-f2bbe5e1"
                    className="chord-main-pic border-radius"
                >
                    <img
                        src="https://uploads-ssl.webflow.com/629e57648149047618a42fe9/62b8a310f1e18e6478527c24_a-major.jpg"
                        loading="lazy"
                        alt=""
                        sizes="100vw"
                        srcSet="https://uploads-ssl.webflow.com/629e57648149047618a42fe9/62b8a310f1e18e6478527c24_a-major-p-500.jpeg 500w, https://uploads-ssl.webflow.com/629e57648149047618a42fe9/62b8a310f1e18e6478527c24_a-major-p-800.jpeg 800w, https://uploads-ssl.webflow.com/629e57648149047618a42fe9/62b8a310f1e18e6478527c24_a-major.jpg 1000w"
                        className="chord-main-pic__image border-radius"
                    />
                </div>
                <div>
                    <div className="section__headline-centered">
                        <h3>Chord variations</h3>
                    </div>
                    <div className="w-dyn-list">
                        <div role="list" className="chords-grid section-fw-chord w-dyn-items">
                            <div role="listitem" className="chord-wrapper w-dyn-item">
                                <div className="chord-toolbar chord-toolbar--absolute">
                                    <div
                                        data-w-id="d6cef4f4-dc5c-0e0b-7943-0221507b12e3"
                                        style={{
                                            WebkitTransform:
                                                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            MozTransform:
                                                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            msTransform:
                                                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                            transform:
                                                "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
                                        }}
                                        className="chord-toolbar__picture w-condition-invisible"
                                    >
                                        <img
                                            alt="A major"
                                            loading="lazy"
                                            style={{
                                                WebkitTransform:
                                                    "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                                MozTransform:
                                                    "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                                msTransform:
                                                    "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                                transform:
                                                    "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
                                            }}
                                            src=""
                                            className="chord-toolbar__image w-dyn-bind-empty"
                                        />
                                    </div>
                                    <a
                                        href="/chord/a-v2"
                                        className="chord-toolbar__title-wrapper w-inline-block"
                                    >
                                        <div className="w-embed">
                                            <div className="chip chip--grey">Alternative A</div>
                                        </div>
                                        <div className="chip w-condition-invisible">Main version</div>
                                    </a>
                                    <div
                                        style={{ opacity: 0, display: "none" }}
                                        className="chord-overlay"
                                    />
                                </div>
                                <div className="hand-popover border-radius">
                                    <div className="hand-popover__finger hand-popover__finger--4">
                                        4
                                    </div>
                                    <div className="hand-popover__finger hand-popover__finger--t">
                                        T
                                    </div>
                                    <div className="hand-popover__finger hand-popover__finger--1">
                                        1
                                    </div>
                                    <div className="hand-popover__finger hand-popover__finger--2">
                                        2
                                    </div>
                                    <div className="hand-popover__finger hand-popover__finger--3">
                                        3
                                    </div>
                                </div>
                                <div className="chord">
                                    <div className="chord__visual">
                                        <div className="chord__neck">
                                            <div className="chord__position" />
                                            <div className="w-embed">
                                                <div className="chord__strings">
                                                    <div className="chord__string chord__string--6">
                                                        <div
                                                            className="chord__finger chord__fret--5"
                                                            fret={5}
                                                            finger={1}
                                                        >
                                                            <div className="chord__finger-circle chord__finger-circle--5">
                                                                {" "}
                                                                1{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chord__string chord__string--5">
                                                        <div
                                                            className="chord__finger chord__fret--7"
                                                            fret={7}
                                                            finger={3}
                                                        >
                                                            <div className="chord__finger-circle chord__finger-circle--7">
                                                                {" "}
                                                                3{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chord__string chord__string--4">
                                                        <div
                                                            className="chord__finger chord__fret--7"
                                                            fret={7}
                                                            finger={4}
                                                        >
                                                            <div className="chord__finger-circle chord__finger-circle--7">
                                                                {" "}
                                                                4{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chord__string chord__string--3">
                                                        <div
                                                            className="chord__finger chord__fret--6"
                                                            fret={6}
                                                            finger={2}
                                                        >
                                                            <div className="chord__finger-circle chord__finger-circle--6">
                                                                {" "}
                                                                2{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chord__string chord__string--2">
                                                        <div
                                                            className="chord__finger chord__fret--5"
                                                            fret={5}
                                                            finger={1}
                                                        >
                                                            <div className="chord__finger-circle chord__finger-circle--5">
                                                                {" "}
                                                                1{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chord__string chord__string--1">
                                                        <div
                                                            className="chord__finger chord__fret--5"
                                                            fret={5}
                                                            finger={1}
                                                        >
                                                            <div className="chord__finger-circle chord__finger-circle--5">
                                                                {" "}
                                                                1{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="chord-sound-wrapper w-condition-invisible">
                                    <div className="chord-sound w-embed">
                                        <div className="material-icon--sound" id="a-v2">
                                            ♪
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChordPage;