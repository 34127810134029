import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import ChordsPage from './chords.js';
import ChordPage from './chord.js';
import NotesPage from "./notes.js";
import HomePage from "./home.js";
import './index.css';
import './styles/styles.scss'

window.addEventListener(
  "hashchange",
  () => {
    window.location.reload();
  },
  false,
);

class App extends Component {
    render() {
        return (
            <Router>
                <Routes>
                        <Route exact path='/' element={< HomePage />}></Route>
                        <Route exact path='/chords' element={< ChordsPage />}></Route>
                        <Route exact path='/chords/chord' element={< ChordPage />}></Route>
                        <Route exact path='/notes' element={< NotesPage />}></Route>
                </Routes>
            </Router>
        );
    }
}

const root = createRoot(document.getElementById('root'));

root.render(<App />);
