export const initNotesGroups = () => {
    const groups = Array.from(document.querySelectorAll(".notes-group"));
    if (groups.length < 1) return;
    groups.forEach((group, gIndex) => {
        const notes = Array.from(group.querySelectorAll(".note"));
        if (notes.length < 2) return;

        notes.forEach((note, index) => {
            if (notes.indexOf(note) === notes.length - 1 || 
            (!note.classList.contains("note--sixteenth") && !note.classList.contains("note--eight"))) return;
            const nextNote = note.nextSibling;

            const noteChar = note.querySelector(".note__character");
            const nextNoteChar = nextNote.querySelector(".note__character");

            const getStyles = el => window.getComputedStyle(el);

            const colorStart = getStyles(note).getPropertyValue("color");
            const colorStop = getStyles(nextNote).getPropertyValue("color");

            const noteOffsetTop = noteChar.getBoundingClientRect().top;
            const nextNoteOffsetTop = nextNoteChar.getBoundingClientRect().top;
            const q = nextNoteOffsetTop - noteOffsetTop;
            if (note.classList.contains("note--sixteenth")) {
                noteChar
                    .insertAdjacentHTML("afterbegin", `
                        <svg class="notes-group__flag" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" width="100%" viewBox="0 0 69 80" fill="none">
                            <rect x="0" 
                                width="100%" 
                                style="transform-origin: 0 0; transform: skewY(${q*(0.83)}deg)"
                                height="8" fill="url(#noteFlagGradient${gIndex}-${index})"/>
                            <rect x="0" 
                                width="100%" 
                                style="transform-origin: 0 0; transform: skewY(${q*(0.83)}deg) translateY(14px)"
                                height="8" fill="url(#noteFlagGradient${gIndex}-${index})"/>
                            <defs>
                                <linearGradient id="noteFlagGradient${gIndex}-${index}">
                                  <stop offset="0%" stop-color="${colorStart}" />
                                  <stop offset="100%" stop-color="${colorStop}" />
                                </linearGradient>
                            </defs>
                        </svg>
                    `);
                return;
            }
            noteChar
                .insertAdjacentHTML("afterbegin", `
                    <svg class="notes-group__flag" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" width="100%" viewBox="0 0 69 80" fill="none">
                        <rect x="0"
                            width="100%" 
                            style="transform-origin: 0 0; transform: skewY(${q*(0.83)}deg)"
                            height="8" fill="url(#noteFlagGradient${gIndex}-${index})"/>
                        <defs>
                            <linearGradient id="noteFlagGradient${gIndex}-${index}">
                              <stop offset="0%" stop-color="${colorStart}" />
                              <stop offset="100%" stop-color="${colorStop}" />
                            </linearGradient>
                        </defs>
                    </svg>
                `);
            return;
        });
    })
}