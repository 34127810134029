import React from 'react';
import './index.css';
import AppHeader from "./compontents/app-header";

function HomePage () {
    return (
        <div>
            <AppHeader/>
        </div>
    );
}


export default HomePage;