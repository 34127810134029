import React, { useEffect } from 'react';
import { 
    Link, 
    animateScroll as scroll } from "react-scroll";
import NotesStave from "./compontents/notes-stave";
import TabsStave from "./compontents/tabs-stave";
import { TabsBar } from "./compontents/tabs-stave/tabs-stave";
import { 
    Note, 
    NotesGroup, 
    NotesBar } from "./compontents/notes-stave/notes-stave";
import AppHeader from "./compontents/app-header";
import PageSection from "./compontents/layout/page-section";
import ContentSection from "./compontents/layout/content-section";
import { initNotesGroups } from "./compontents/notes-stave/init-notes";
import { ChordCardSimple } from "./compontents/chord-card/chord-card";
import { 
    initChordResize, 
    initFingerHover } from './compontents/chord-card/init-chord';
import { HeroHeadline, HeroSection, HeroSubheadline } from "./compontents/hero-section/hero-section";
import TextBlock from "./compontents/layout/text-block/text-block";
import NoteScheme from "./illustrations/note-scheme/note-scheme";
import { 
    NoteBrick, 
    NotesLengthGrid, 
    NotesLengthGridRow } from "./compontents/notes-length-grid/notes-length-grid";
import SoundButton from "./compontents/sound-btn/sound-btn";
import {elMultiplier} from "./scripts/helpers";
import {PianoKeys, PianoKeysOctave} from "./compontents/piano-keys/piano-keys";

function componentDidMount() {
    initChordResize();
    initFingerHover();
};

function NotesPage () {
    useEffect(() => {
        initNotesGroups();
        componentDidMount();
    }, []);
    return (
        <div>
            <AppHeader/>
            <HeroSection imgUrl="https://uploads-ssl.webflow.com/629e5388ef3d3bca1315d8de/640249d3f5eb58424a36b522_string-555070">
                <HeroHeadline>
                    {/* Basics <br />
                    <span className="hero-headline__italic">of</span> music <br />notation */}
                    Музична<br />
                    нотація
                </HeroHeadline>
                <HeroSubheadline>
                    {/* <span className="text-span-14">Part I: </span> */}
                    <span className="text-span-14">Частина I: </span>
                    <br />
                    <span className="text-italic">
                        The way a Note looks<br />
                        tells you how long it lasts
                    </span>
                </HeroSubheadline>
            </HeroSection>
            <PageSection id="section1">
                <TextBlock headline="Notes length">
                    Learn about the basic principles of music notation and guitar tabs in&nbsp;just half an&nbsp;hour. Learn how to&nbsp;read guitar&nbsp;tabs and sheet music, and play any&nbsp;songs you&nbsp;like.
                    <br /><br />
                    Ready? Then let's get started...
                </TextBlock>

                <ContentSection>
                    <TextBlock>
                        Ноти записують на нотному стані, який складається з п'яти ліній та чотирьох проміжків між ними:
                    </TextBlock>
                    <NotesStave noteKey={null} />
                </ContentSection>

                <ContentSection>
                    <TextBlock headline="Ноти:">
                        Ноти можуть бути розташовані на лініях:
                    </TextBlock>
                    <NotesStave noteKey={null}>
                        <Note colored note="mi" length="full"/>
                        <Note colored note="sol" length="full"/>
                        <Note colored note="si" length="full"/>
                        <Note colored note="re" shift="2" length="full"/>
                        <Note colored note="fa" shift="2" length="full"/>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        Або ж в проміжках між лініями:
                    </TextBlock>
                    <NotesStave noteKey={null}>
                        <Note colored note="fa" length="full"/>
                        <Note colored note="la" length="full"/>
                        <Note colored note="do" shift="2" length="full"/>
                        <Note colored note="mi" shift="2" length="full"/>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                       Хоч нотний стан складається з п'яти основних ліній, можна додавати додкткові лінії, коли вони потрібні:
                    </TextBlock>
                    <NotesStave noteKey={null} extraPaddingTop="2" extraPaddingBottom="1">
                        <Note colored note="do" length="full" />
                        <Note colored note="re" length="full" />
                        <Note colored note="sol" length="full" shift="2" />
                        <Note colored note="la" length="full" shift="2" />
                        <Note colored note="si" length="full" shift="2" />
                        <Note colored note="do" length="full" shift="3" />
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                       Те, де розташована нота на нотному стані, каже нам про те, як вона звучить.
                    </TextBlock>
                    <NotesStave noteKey={null} extraPaddingTop="2" extraPaddingBottom="1">
                        <Note colored note="do" length="full" />
                        <Note colored note="re" length="full" />
                        <Note colored note="mi" length="full" />
                        <Note colored note="fa" length="full" />
                        <Note colored note="sol" length="full" />
                        <Note colored note="la" length="full" />
                        <Note colored note="si" length="full" />
                        <Note colored note="do" length="full" shift="2" />
                        <Note colored note="re" length="full" shift="2" />
                        <Note colored note="mi" length="full" shift="2" />
                        <Note colored note="fa" length="full" shift="2" />
                        <Note colored note="sol" length="full" shift="2" />
                        <Note colored note="la" length="full" shift="2" />
                        <Note colored note="si" length="full" shift="2" />
                        <Note colored note="do" length="full" shift="3" />
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        Впевнені, вам вже знайомі назви нот "До", "Ре", "Мі", "Фа", "Соль", "Ля" та "Сі".
                        Зверніть також увагу, що ноти мають альтернативні назви, наприклад нота "До" 
                        ще може називатись латинською літерою "C", а нота "Соль" — латинською "G". 
                        В гітарній музиці вам часто будуть зустрічатися саме скорочені назви нот.
                    </TextBlock>
                    <NotesStave noteKey={null} extraPaddingBottom="2">
                        <Note colored note="do" length="full" titled />
                        <Note colored note="re" length="full" titled />
                        <Note colored note="mi" length="full" titled />
                        <Note colored note="fa" length="full" titled />
                        <Note colored note="sol" length="full" titled />
                        <Note colored note="la" length="full" titled />
                        <Note colored note="si" length="full" titled />
                    </NotesStave>
                    <SoundButton slug="notes-7" mod="shaded" />
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        Ноти повторюються по колу від ноти "До" до ноти "Сі", а потім знов нота "До".
                        Це необхідно, бо одна і та сама нота може звучати як вище, так і нижче.
                    </TextBlock>
                    <NotesStave noteKey={null} extraPaddingTop="2" extraPaddingBottom="1">
                        <Note note="do" length="full" titled />
                        <Note note="re" length="full" titled />
                        <Note note="mi" length="full" titled />
                        <Note note="fa" length="full" titled />
                        <Note note="sol" length="full" titled />
                        <Note note="la" length="full" titled />
                        <Note note="si" length="full" titled />
                        <Note colored note="do" length="full" titled shift="2" />
                        <Note colored note="re" length="full" titled shift="2" />
                        <Note colored note="mi" length="full" titled shift="2" />
                        <Note colored note="fa" length="full" titled shift="2" />
                        <Note colored note="sol" length="full" titled shift="2" />
                        <Note colored note="la" length="full" titled shift="2" />
                        <Note colored note="si" length="full" titled shift="2" />
                        <Note colored note="do" length="full" titled shift="3" />
                    </NotesStave>
                    <SoundButton slug="notes-7-7-1" mod="shaded" />
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        Також дуже зручно вчити ноти, дивлячись на клавіатуру фортепіано.
                        Одна октава піаніно — це і є сім нот. Вони розташовані на білих клавішах.
                    </TextBlock>
                    <PianoKeys>
                        <PianoKeysOctave />
                    </PianoKeys>
                    <TextBlock>
                        Можливо тут у вас виникне питання навіщо ж тоді чорні клавіши і де поділася чорна клавіша між нотами "Мі" та "Фа".
                        Ми пояснимо це трохи нижче.
                    </TextBlock>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        Давайте подивимося на ноти на гітарному грифі.
                        Наводьте на ноту, щоб побачити де вона розташована на гітарному грифі при класичному налаштуванні гітари.
                    </TextBlock>
                    <NotesStave noteKey={null} extraPaddingTop="2" extraPaddingBottom="1">
                        <Note colored note="do" length="full" titled />
                        <Note colored note="re" length="full" titled />
                        <Note colored note="mi" length="full" titled />
                        <Note colored note="fa" length="full" titled />
                        <Note colored note="sol" length="full" titled />
                        <Note colored note="la" length="full" titled />
                        <Note colored note="si" length="full" titled />
                        <Note colored note="do" length="full" titled shift="2" />
                        <Note colored note="re" length="full" titled shift="2" />
                        <Note colored note="mi" length="full" titled shift="2" />
                        <Note colored note="fa" length="full" titled shift="2" />
                        <Note colored note="sol" length="full" titled shift="2" />
                        <Note colored note="la" length="full" titled shift="2" />
                        <Note colored note="si" length="full" titled shift="2" />
                        <Note colored note="do" length="full" titled shift="3" />
                    </NotesStave>
                    {/* <ChordCardSimple data={[{fret: 7, string: 2}]} large={true} /> */}
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        Одна і та сама нота в різних тональностях звучить по-різному:
                    </TextBlock>
                    <NotesStave noteKey={null}>
                        <Note colored={1} note="do" length="full" titled />
                        <Note colored={2} note="do" length="full" titled shift="2" />
                        <Note colored={3} note="do" length="full" titled shift="3" />
                    </NotesStave>
                    <SoundButton slug="note-do-tone" mod="shaded" />
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        Повернемось до нот на клавіатурі фортепіано і до чорних клавіш.
                        На чорних клавішах розташовані півтони, інакше кажучи це підвищення ноти на один півтон.
                        Такі півтони позначаються символом решітки <span className="diez-symbol">&#9839;</span>, яку називають "Діє́з" або "Діе́з".
                    </TextBlock>
                    <PianoKeys>
                        <PianoKeysOctave diezLabeled={true} />
                    </PianoKeys>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        Між нотами "Мі" та "Фа", а також між нотами "Сі" та "До" чорні клавіши відсутні.
                        Це не випадково, справа в тому, що не існує півтонів між цими нотами.
                        Чому? Коротка відповідь: так влаштована природа.
                        На даний момент вам достатньо просто запам'ятати цей нюанс.
                    </TextBlock>
                    <PianoKeys>
                        <PianoKeysOctave diezLabeled={true} />
                        <PianoKeysOctave diezLabeled={true} />
                    </PianoKeys>
                </ContentSection>

                <ContentSection>
                    <TextBlock headline="Нота.">
                        Але що це за кругляшки? — можете спитати ви. Можливо ви звикли, що ноти виглядать дещо інакше. 
                        Давайте подивимось ближче на частини, з яких може складатися нота.
                    </TextBlock>
                    <NoteScheme />
                    <TextBlock>
                        Але чому ноти мають різний вигляд?
                        Час поговорити про тривалості нот...
                    </TextBlock>
                </ContentSection>

                <ContentSection>
                    <TextBlock headline="Тривалості.">
                       Те, як виглядає нота, каже вам про те, як довго вона звучить, або її тривалість. 
                       Ось приклад найпопулярніших тривалостей нот від найдовшої, до найкоротшої тривалості:
                    </TextBlock>
                    <NotesStave noteKey={null}>
                        <NotesBar>
                            <Note colored={1} note="fa" length="full"/>
                            <Note colored={2} note="fa" length="half"/>
                            <Note colored={3} note="fa" length="quart"/>
                            <Note colored={4} note="fa" length="eight"/>
                            <Note colored={5} note="fa" length="sixteenth"/>
                        </NotesBar>
                    </NotesStave>
                    <SoundButton slug="note-lengths" mod="shaded" />
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                       Нижче наведено візуальне порівняння тривалостей нот для наглядності.
                       Наприклад одна ціла нота дорівнює двом половинним, а одна половинна — це дві четвертні ноти і так далі.
                       Все доволі просто, потрібно лише трохи згадати математику зі школи.
                    </TextBlock>
                    <NotesLengthGrid>
                        <NotesLengthGridRow comment="Ціла нота">
                            <NoteBrick length="full" />
                        </NotesLengthGridRow>
                        <NotesLengthGridRow comment="Половинні ноти">
                            {elMultiplier(<NoteBrick />, 2)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow comment="Четвертні ноти">
                            {elMultiplier(<NoteBrick length="quart" />, 4)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow comment="Восьмі ноти">
                            {elMultiplier(<NoteBrick length="eight" />, 8)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow comment="Шістнадцяті ноти">
                            {elMultiplier(<NoteBrick length="sixteenth" />, 16)}
                        </NotesLengthGridRow>
                    </NotesLengthGrid>
                    <SoundButton slug="note-lengths-comp" mod="shaded" />
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                    Іноді буває необхідно трохи подовжити тривалість ноти. Для цього використовують крапочки. 
                    Одна крапочка подовжує ноту в 1.5 раз, а дві крапочки подовжують ноту на 1,75 раз.
                    Нижче наведено наглядний приклад того, як одна крапка подовжуює ноти.
                    </TextBlock>
                    <TextBlock>
                        В цьому прикладі довжина половинної ноти подовжується в 1,5 рази:
                    </TextBlock>
                    <NotesLengthGrid>
                        <NotesLengthGridRow comment="Половинні ноти">
                            {elMultiplier(<NoteBrick />, 2)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow comment="Половинна подовжена нота">
                            <NoteBrick extend={1} />
                            {elMultiplier(<NoteBrick length="eight" greyscale />, 2)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow>
                            {elMultiplier(<NoteBrick length="eight" greyscale />, 8)}
                        </NotesLengthGridRow>
                    </NotesLengthGrid>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        А в цьому прикладі довжина четверної ноти подовжується в 1,5 рази:
                    </TextBlock>
                    <NotesLengthGrid>
                        <NotesLengthGridRow comment="Четвертні ноти">
                            {elMultiplier(<NoteBrick length="quart" />, 4)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow comment="Четвертні подовжені ноти">
                            {elMultiplier(<NoteBrick length="quart" extend={1} />, 2)}
                            {elMultiplier(<NoteBrick length="eight" greyscale />, 2)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow>
                            {elMultiplier(<NoteBrick length="eight" greyscale />, 8)}
                        </NotesLengthGridRow>
                    </NotesLengthGrid>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        А от як працюють дві крапки. В цьому прикладі, в нижньому рядку, довжина половинної ноти подовжується в 1,75 рази:
                    </TextBlock>
                    <NotesLengthGrid>
                        <NotesLengthGridRow comment="Половинні ноти">
                            {elMultiplier(<NoteBrick />, 2)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow>
                            <NoteBrick extend={1} />
                            {elMultiplier(<NoteBrick length="eight" greyscale />, 2)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow comment="Половинна подовжена нота">
                            <NoteBrick extend={2} />
                            <NoteBrick length="eight" greyscale />
                        </NotesLengthGridRow>
                        <NotesLengthGridRow>
                            {elMultiplier(<NoteBrick length="eight" greyscale />, 8)}
                        </NotesLengthGridRow>
                    </NotesLengthGrid>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        І приклад подовження четвертної ноти в 1,75 разів:
                    </TextBlock>
                    <NotesLengthGrid>
                        <NotesLengthGridRow comment="Четвертні ноти">
                            {elMultiplier(<NoteBrick length="quart" />, 4)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow>
                            {elMultiplier(<NoteBrick length="quart" extend={1} />, 2)}
                            {elMultiplier(<NoteBrick length="eight" greyscale />, 2)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow comment="Четвертні подовжені ноти">
                            {elMultiplier(<NoteBrick length="quart" extend={2} />, 2)}
                            <NoteBrick length="eight" greyscale />
                        </NotesLengthGridRow>
                        <NotesLengthGridRow>
                            {elMultiplier(<NoteBrick length="eight" greyscale />, 8)}
                        </NotesLengthGridRow>
                    </NotesLengthGrid>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        Ось як виглядають подовжені ноти на нотному стані:
                    </TextBlock>
                    <NotesStave noteKey={null}>
                        <Note note="fa" length="quart" colored={1} extend={1} />
                        <Note note="fa" length="eight" colored={2} extend={2} />
                        <Note note="fa" length="sixteenth" colored={3} extend={2} />
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock headline="Групи.">
                        Часто ви будете бачити ноти, які поєднані одною, або двома рисками. 
                        Не варто лякатися, це лише групи восьмих та щістнацятих нот.
                        Їх поєднують для зручності зчитування нот (думаємо, ще і через лінь, бо раніше ноти писали руками, а писати флажки щоразу досить втомлює.)
                    </TextBlock>
                    <NotesStave noteKey={null} extraPaddingTop="3" extraPaddingBottom="1">
                        <NotesBar comment={["Це три восьмі ноти", 1]}>
                            <Note colored length="eight" note="do" />
                            <Note colored length="eight" note="re" />
                            <Note colored length="eight" note="mi" />
                        </NotesBar>
                        <NotesBar comment={["І це теж ті самі три восьмі ноти", 1]}>
                            <NotesGroup>
                                <Note colored length="eight" note="do" />
                                <Note colored length="eight" note="re" />
                                <Note colored length="eight" note="mi" />
                            </NotesGroup>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        Щістнадцяті ноти поєднують в групи за тим же принципом, але вже двома рисками.
                    </TextBlock>
                    <NotesStave noteKey={null} extraPaddingBottom="1">
                        <NotesBar>
                            <Note colored length="sixteenth" note="do" />
                            <Note colored length="sixteenth" note="re" />
                            <Note colored length="sixteenth" note="mi" />
                        </NotesBar>
                        <NotesBar>
                            <NotesGroup>
                                <Note colored length="sixteenth" note="do" />
                                <Note colored length="sixteenth" note="re" />
                                <Note colored length="sixteenth" note="mi" />
                            </NotesGroup>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        Давайте подивимося кілька різних варіантів груп нот.
                    </TextBlock>
                    <NotesStave noteKey={null} extraPaddingBottom="1">
                        <NotesBar>
                            <Note colored={1} length="eight" note="do" />
                            <Note colored={2} length="eight" note="mi" />
                            <Note colored={3} length="eight" note="sol" />
                        </NotesBar>
                        <NotesBar>
                            <NotesGroup>
                                <Note colored={1} length="eight" note="do" />
                                <Note colored={2} length="eight" note="mi" />
                                <Note colored={3} length="eight" note="sol" />
                            </NotesGroup>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <NotesStave noteKey={null} extraPaddingBottom="1">
                        <NotesBar>
                            <Note colored={1} length="sixteenth" note="do" />
                            <Note colored={2} length="sixteenth" note="mi" />
                            <Note colored={3} length="sixteenth" note="sol" />
                        </NotesBar>
                        <NotesBar>
                            <NotesGroup>
                                <Note colored={1} length="sixteenth" note="do" />
                                <Note colored={2} length="sixteenth" note="mi" />
                                <Note colored={3} length="sixteenth" note="sol" />
                            </NotesGroup>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <NotesStave noteKey={null} extraPaddingBottom="1">
                        <NotesBar>
                            <Note colored={1} length="eight" note="do" />
                            <Note colored={2} length="eight" note="do" />
                            <Note colored={3} length="eight" note="do" />
                        </NotesBar>
                        <NotesBar>
                            <NotesGroup>
                                <Note colored={1} length="eight" note="do" />
                                <Note colored={2} length="eight" note="do" />
                                <Note colored={3} length="eight" note="do" />
                            </NotesGroup>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <NotesStave noteKey={null} extraPaddingBottom="1">
                        <NotesBar>
                            <Note colored={1} length="sixteenth" note="do" />
                            <Note colored={2} length="sixteenth" note="do" />
                            <Note colored={3} length="sixteenth" note="do" />
                        </NotesBar>
                        <NotesBar>
                            <NotesGroup>
                                <Note colored={1} length="sixteenth" note="do" />
                                <Note colored={2} length="sixteenth" note="do" />
                                <Note colored={3} length="sixteenth" note="do" />
                            </NotesGroup>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <NotesStave noteKey={null} extraPaddingBottom="1">
                        <NotesBar>
                            <Note colored={1} length="eight" note="mi" />
                            <Note colored={2} length="eight" note="re" />
                            <Note colored={3} length="eight" note="do" />
                        </NotesBar>
                        <NotesBar>
                            <NotesGroup>
                                <Note colored={1} length="eight" note="mi" />
                                <Note colored={2} length="eight" note="re" />
                                <Note colored={3} length="eight" note="do" />
                            </NotesGroup>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <NotesStave noteKey={null} extraPaddingBottom="1">
                        <NotesBar>
                            <Note colored={1} length="sixteenth" note="mi" />
                            <Note colored={2} length="sixteenth" note="re" />
                            <Note colored={3} length="sixteenth" note="do" />
                        </NotesBar>
                        <NotesBar>
                            <NotesGroup>
                                <Note colored={1} length="sixteenth" note="mi" />
                                <Note colored={2} length="sixteenth" note="re" />
                                <Note colored={3} length="sixteenth" note="do" />
                            </NotesGroup>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <NotesStave noteKey={null} extraPaddingBottom="1">
                        <NotesBar>
                            <Note colored={1} length="eight" note="mi" />
                            <Note colored={2} length="eight" note="re" />
                            <Note colored={3} length="eight" note="fa" />
                            <Note colored={4} length="eight" note="sol" />
                        </NotesBar>
                        <NotesBar>
                            <NotesGroup>
                                <Note colored={1} length="eight" note="mi" />
                                <Note colored={2} length="eight" note="re" />
                            </NotesGroup>
                            <NotesGroup>
                                <Note colored={3} length="eight" note="fa" />
                                <Note colored={4} length="eight" note="sol" />
                            </NotesGroup>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <NotesStave noteKey={null} extraPaddingBottom="1">
                        <NotesBar>
                            <Note colored={1} length="sixteenth" note="mi" />
                            <Note colored={2} length="sixteenth" note="re" />
                            <Note colored={3} length="sixteenth" note="fa" />
                            <Note colored={4} length="sixteenth" note="sol" />
                        </NotesBar>
                        <NotesBar>
                            <NotesGroup>
                                <Note colored={1} length="sixteenth" note="mi" />
                                <Note colored={2} length="sixteenth" note="re" />
                            </NotesGroup>
                            <NotesGroup>
                                <Note colored={3} length="sixteenth" note="fa" />
                                <Note colored={4} length="sixteenth" note="sol" />
                            </NotesGroup>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock headline="Паузи.">
                        Паузи в музиці не менш важливі, ніж ноти. 
                        Для пауз є спеціальні символи, а тривалості пауз співпадають з тривалостями нот, що, погодьтесь, дуже зручно.
                        Давайте для початку ще раз поглянемо на тривалості нот, які ми вже знаємо:
                    </TextBlock>
                    <NotesStave noteKey={null}>
                        <NotesBar>
                            <Note colored={1} note="fa" length="full"/>
                            <Note colored={2} note="fa" length="half"/>
                            <Note colored={3} note="fa" length="quart"/>
                            <Note colored={4} note="fa" length="eight"/>
                            <Note colored={5} note="fa" length="sixteenth"/>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        А тепер поглянемо як будуть виглядати тривалості пауз, які відповідають тривалостям цих нот:
                    </TextBlock>
                    <NotesStave noteKey={null}>
                        <NotesBar>
                            <Note colored={1} note="fa" length="full"/>
                            <Note colored={2} note="fa" length="half"/>
                            <Note colored={3} note="fa" length="quart"/>
                            <Note colored={4} note="fa" length="eight"/>
                            <Note colored={5} note="fa" length="sixteenth"/>
                        </NotesBar>
                    </NotesStave>
                    <NotesStave noteKey={null}>
                        <NotesBar>
                            <Note colored={1} note="pause" length="full"/>
                            <Note colored={2} note="pause" length="half"/>
                            <Note colored={3} note="pause" length="quart"/>
                            <Note colored={4} note="pause" length="eight"/>
                            <Note colored={5} note="pause" length="sixteenth"/>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        А тепер можемо візуально порівняти довжину пауз, як ми раніше робили з нотами.
                    </TextBlock>
                    <NotesLengthGrid>
                        <NotesLengthGridRow comment="Ціла пауза">
                            <NoteBrick pause length="full" />
                        </NotesLengthGridRow>
                        <NotesLengthGridRow comment="Половинні паузи">
                            {elMultiplier(<NoteBrick pause />, 2)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow comment="Четвертні паузи">
                            {elMultiplier(<NoteBrick pause length="quart" />, 4)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow comment="Восьмі паузи">
                            {elMultiplier(<NoteBrick pause length="eight" />, 8)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow comment="Шістнадцяті паузи">
                            {elMultiplier(<NoteBrick pause length="sixteenth" />, 16)}
                        </NotesLengthGridRow>
                    </NotesLengthGrid>
                </ContentSection>

                <ContentSection>
                    <TextBlock headline="Ключ.">
                        На початку кожного музичного рядка стоїть знак ключа. 
                        У гітарній музиці використовується знак "Соль".
                    </TextBlock>
                    <NotesStave noteKeyColored={1}>
                        {elMultiplier(<Note note="fa" length="quart"/>, 4)}
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock headline="Такти:">
                        Стан поділяється на такти лініями. 
                        В кінці кожної музичної частини для зручності ставиться подвійна лінія.
                    </TextBlock>
                    <NotesStave>
                        <NotesBar mod="colored-1" comment={["ця умовна музикальна частина складається з чотирьох тактів", 4]} />
                        <NotesBar mod="colored-2" />
                        <NotesBar mod="colored-3" />
                        <NotesBar mod={["end", "colored-3"]} />
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock headline="Повтори:">
                        Іноді буває необхідно повторити одну і ту саму частину мелодії двічі, рідше тричі. 
                        Щоб не писати одні і ті самі ноти кілька разів, використовують символ повторення,
                        де кількість крапок означає кількість повторень. Честіше за все буває дві крапки.
                    </TextBlock>
                    <NotesStave>
                        <NotesBar mod="colored-1" comment={["ці три такти повторюються двічі від початку", 4]} />
                        <NotesBar mod="colored-1" />
                        <NotesBar mod="colored-1" />
                        <NotesBar mod={["repeat-end", "colored-1"]} />
                        {elMultiplier(<NotesBar />, 2)}
                    </NotesStave>
                    <TextBlock>
                        Якщо потрібно повторити частину від самого початку, то символ початку повторення можно пропустити, як на прикладі вище.
                        Але якщо повторення починається пізніше, потрібно використовувати відкриваючий і закриваючий символи повторення:
                    </TextBlock>
                    <NotesStave>
                        <NotesBar />
                        <NotesBar mod={["repeat-start", "colored-2"]} comment={["ці три такти повторюються двічі", 4]} />
                        <NotesBar mod="colored-2" />
                        <NotesBar mod="colored-2" />
                        <NotesBar mod={["repeat-end", "colored-2"]} />
                    </NotesStave>
                    <TextBlock>
                        Нижче наведено приклад потрійного повторення з трьома точками:
                    </TextBlock>
                    <NotesStave>
                        <NotesBar />
                        <NotesBar mod={["repeat-start-3", "colored-3"]} comment={["ці три такти повторюються двічі", 4]} />
                        <NotesBar mod="colored-3" />
                        <NotesBar mod="colored-3" />
                        <NotesBar mod={["repeat-end-3", "colored-3"]} />
                    </NotesStave>
                </ContentSection>
                
                <ContentSection>
                    <TextBlock headline="Метр:">
                        На нотному стані ви часто будете зустрічати цифри справа від ключа.
                        Давайте розберемось що ж вони означають.
                    </TextBlock>
                    <NotesStave nums={[4, 4]} numTopColor={1} numBottomColor={1}>
                    </NotesStave>
                </ContentSection>
                
                <ContentSection>
                    <TextBlock>
                        Нижньою цифрою позначають довжину ноти, як одиниця виміру довжини такту.
                        Це як в метричній системі: можна міряти довжину метрами, можна сантиметрами, а можна й міліметрами.
                        В даному випадку у нас вказано цифру чотири, отже міряти ми будемо четвертною нотою.
                    </TextBlock>
                    <NotesStave nums={[4, 4]} numBottomColor={1}>
                        <NotesBar>
                            <Note colored={1} length="quart" />
                        </ NotesBar>
                        <NotesBar />
                    </NotesStave>
                </ContentSection>
                
                <ContentSection>
                    <TextBlock>
                        Верхньою цифрою позначають кількість нот в одному такті.
                        Тобто практично 4/4 можна прочитати як "Чотири четвертих ноти".
                    </TextBlock>
                    <NotesStave nums={[4, 4]} numTopColor={1}>
                        <NotesBar>
                            {elMultiplier(<Note colored={1} length="quart" />, 4)}
                        </ NotesBar>
                        <NotesBar />
                    </NotesStave>
                </ContentSection>
                
                <ContentSection>
                    <TextBlock>
                        А тепер згадуємо математику і те, що вчили в цій статті раніше.
                        Ми вже знаємо, що дві восьмих ноти дорівнюють одній четвертій.
                        Отже ми можежемо використати дві восьмих замість одної четверної ноти:
                    </TextBlock>
                    <NotesStave nums={[4, 4]}>
                        <NotesBar>
                            {elMultiplier(<Note colored={1} length="quart" />, 3)}
                            {elMultiplier(<Note colored={2} length="eight" />, 2)}
                        </ NotesBar>
                        <NotesBar />
                    </NotesStave>
                </ContentSection>

                <ContentSection>
                    <TextBlock>
                        Для наглядності згадаємо ще раз нашу схему з порівнянням тривалостей.
                        Тут добре видно, що дві восьмі ноти дорівнюють одній четвертій.
                    </TextBlock>
                    <NotesLengthGrid>
                        <NotesLengthGridRow>
                            {elMultiplier(<NoteBrick length="quart" />, 4)}
                        </NotesLengthGridRow>
                        <NotesLengthGridRow>
                            {elMultiplier(<NoteBrick length="eight" />, 8)}
                        </NotesLengthGridRow>
                    </NotesLengthGrid>
                </ContentSection>
                
                <ContentSection>
                    <TextBlock>
                        Тому ми можемо навіть використати вісім восьмих нот:
                    </TextBlock>
                    <NotesStave nums={[4, 4]}>
                        <NotesBar>
                            {elMultiplier(<Note colored={2} length="eight" />, 8)}
                        </ NotesBar>
                        <NotesBar />
                    </NotesStave>
                </ContentSection>
                
                <ContentSection>
                    <TextBlock>
                        Або ж використати четвертну паузу замість четвертної ноти:
                    </TextBlock>
                    <NotesStave nums={[4, 4]}>
                        <NotesBar>
                            <Note colored={1} note="pause" length="quart" />
                            {elMultiplier(<Note colored={2} length="eight" />, 6)}
                        </ NotesBar>
                        <NotesBar />
                    </NotesStave>
                </ContentSection>
                
                <ContentSection>
                    <TextBlock>
                        Або ж половинну паузу замість двох четвертних нот:
                    </TextBlock>
                    <NotesStave nums={[4, 4]}>
                        <NotesBar>
                            <Note colored={1} note="pause" length="half" />
                            {elMultiplier(<Note colored={2} length="eight" />, 4)}
                        </ NotesBar>
                        <NotesBar />
                    </NotesStave>
                </ContentSection>
                
                <ContentSection>
                    <TextBlock>
                        Чи навіть використати дві половинні ноти, які будуть дрівнювати чотирьом четвертним нотам:
                    </TextBlock>
                    <NotesStave nums={[4, 4]}>
                        <NotesBar>
                            {elMultiplier(<Note colored={1} />, 2)}
                        </ NotesBar>
                        <NotesBar />
                    </NotesStave>
                </ContentSection>
                
                <ContentSection>
                    <TextBlock>
                        Так само ми можемо микористати систему 3/4, тобто три четвертних ноти в одному такті.
                    </TextBlock>
                    <NotesStave nums={[3, 4]}>
                        <NotesBar>
                            {elMultiplier(<Note colored={1} length="quart" />, 3)}
                        </ NotesBar>
                        <NotesBar />
                    </NotesStave>
                </ContentSection>


                <ContentSection>
                    {/* <Link
                        activeClass="active"
                        to="section1"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >Scroll to top</Link> */}

                    <NotesStave nums={[3, 16]}>
                        <NotesBar>
                            <Note colored={3} length="sixteenth" />
                            <Note colored={2} length="sixteenth" />
                            <Note colored={1} length="sixteenth" />
                        </NotesBar>
                        <NotesBar>
                            <NotesGroup>
                                <Note colored={3} length="sixteenth" />
                                <Note colored={2} length="sixteenth" />
                                <Note colored={1} length="sixteenth" />
                            </NotesGroup>
                        </NotesBar>
                    </NotesStave>
                </ContentSection>
                <ContentSection>
                    <TabsStave>
                        <TabsBar nums={[
                            {finger: 0, fret: 3, note: "quart"}, 
                            {finger: 5, fret: 5, note: "quart"}, 
                            {finger: 4, fret: 4, note: "half-x2"}, 
                            {finger: 6, fret: 6, note: "quart"}]} />
                        <TabsBar nums={[
                            {finger: 0, fret: 3, note: "full"}, 
                            {finger: 5, fret: 5, note: "quart"}, 
                            {finger: 4, fret: 4, note: "quart"}, 
                            {finger: 6, fret: 6, note: "quart"}]} />
                        <TabsBar nums={[
                            {finger: 0, fret: 3, note: "full"}, 
                            {finger: 5, fret: 5, note: "eight"}, 
                            {finger: 4, fret: 4, note: "eight"}, 
                            {finger: 6, fret: 6, note: "eight"}]} />
                    </TabsStave>
                    <NotesStave>
                        <NotesBar nums={[
                            {finger: 0, fret: 3, note: "do", length: "quart", colored: 1}, 
                            {finger: 3, fret: 2, note: "mi", length: "quart", colored: 1}, 
                            {finger: 4, fret: 4, note: "sol", length: "half-x2", colored: 1}, 
                            {finger: 6, fret: 6, note: "la", length: "quart", colored: 1}]} />
                        <NotesBar nums={[
                            {finger: 0, fret: 3, note: "do", length: "full", colored: 2}, 
                            {finger: 1, fret: 2, note: "re", length: "quart", colored: 2}, 
                            {finger: 3, fret: 2, note: "mi", length: "quart", colored: 2}, 
                            {finger: 6, fret: 6, note: "la", length: "quart", colored: 2}]} />
                        <NotesBar nums={[
                            {finger: 0, fret: 3, note: "do", length: "full", colored: 3}, 
                            {finger: 1, fret: 2, note: "re", length: "eight", colored: 3}, 
                            {finger: 3, fret: 2, note: "mi", length: "eight", colored: 3}, 
                            {finger: 6, fret: 6, note: "si", length: "sixteenth", shift: 2, colored: 3}]} />
                    </NotesStave>
                </ContentSection>
            </PageSection>
        </div>
    );
}

export default NotesPage;
