import React from 'react';

export const TabsBar = ({nums}) => {
    const tabs = nums.map(({fret, finger, note = "full"}, index) => {
        return (
            <div className={`tabs-grid__note tabs-grid__note--${fret} tabs-grid__note--${note}`} key={index}>
                <span>{finger}</span>
            </div>
        )
    });
    return (
        <div className="tabs-grid__tabs-bar">
            {tabs}
        </div>
    );
};

const TabsStave = ({nums = [], children}) => {
    return (
        <div className="section__overflow">
            <div className="section__scroll">
                <div className="tabs-grid">
                    <div className="tabs-grid__row">
                        <div className="tabs-grid__stave">
                            {children}
                            <div className="tabs-grid__tabs-nums">
                                <div className="tabs-grid__num-char">1</div>
                                <div className="tabs-grid__num-char">2</div>
                                <div className="tabs-grid__num-char">3</div>
                                <div className="tabs-grid__num-char">4</div>
                                <div className="tabs-grid__num-char">5</div>
                                <div className="tabs-grid__num-char">6</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TabsStave;