import React, {useEffect} from 'react';
import './index.css';
import AppHeader from "./compontents/app-header";
import ChordsGrid from "./compontents/chords-grid";
import { initChordResize, initBarre, initFingerHover } from './compontents/chord-card/init-chord';

function componentDidMount() {
    initChordResize();
    initBarre();
    initFingerHover();
};


function ChordsPage () {
    useEffect(() => {
        componentDidMount();
    }, []);
    return (
        <div>
            <AppHeader/>
            <section className="content-wrapper">
                <div className="collection-list-wrapper">
                    <ChordsGrid/>
                </div>
            </section>
        </div>
    );
}


export default ChordsPage;