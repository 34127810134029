import React, {useEffect, useRef} from 'react';
import SoundButton from "../../compontents/sound-btn/sound-btn";

const ChordFinger = ({ index, fret, finger, barre = 0 }) => {
    return (
        <div className={`chord__string chord__string--${index}`}>
            <div 
                className={`chord__finger chord__fret--${fret}`} 
                fret={fret} 
                finger={finger} 
                barre={barre}>
                <div className={`chord__finger-circle chord__finger-circle--${(fret !== -1 &&  fret !== 0 ? 2 : fret)}`}>
                    {finger}
                </div>
            </div>
        </div>
    );
};

const handPopoverFinger = ["4", "T", "1", "2", "3"].map(el => {
    const id = el === "T" ? el.toLowerCase() : el;
    return <div className={`hand-popover__finger hand-popover__finger--${id}`} key={`finger-popover-${el}`}>{el}</div>
})

const ChotdPosition = ({frets}) => {
    const fretsList = frets.filter(e => Number(e) > 0);
    const minFret = Math.min(...fretsList);
    if (minFret > 1) {
        return <div className="chord__position" />
    }
    return null
};

const findBarre = (array) => {
    const arr = [
        array[0] === null ? -1 : array[0],
        array[1] === null ? -1 : array[1],
        array[2] === null ? -1 : array[2],
        array[3] === null ? -1 : array[3],
        array[4] === null ? -1 : array[4],
        array[5] === null ? -1 : array[5]
    ];

    let newArr = [];
    let lastItem;

    for(let i = 0; i < arr.length; i++) {
        if (arr[i] === lastItem) {
            newArr.push(i);
            continue;
        }
        for(let j = i + 1; j <= arr.length; j++) {
            if (arr[i] > 0 && arr[i] === arr[j]) {
                newArr.push(i);
                lastItem = arr[i];
                break;
            };
        }
    }

    return newArr;
}

const ChordCard = ({data, large = false}) => {
    const {
        "Full name": fullName,
        "Short name": shortName,
        "String 6: Fret": string6Fret,
        "String 6: Finger": string6Finger,
        "String 5: Fret": string5Fret,
        "String 5: Finger": string5Finger,
        "String 4: Fret": string4Fret,
        "String 4: Finger": string4Finger,
        "String 3: Fret": string3Fret,
        "String 3: Finger": string3Finger,
        "String 2: Fret": string2Fret,
        "String 2: Finger": string2Finger,
        "String 1: Fret": string1Fret,
        "String 1: Finger": string1Finger,
        "Main picture": mainPic,
        Slug
    } = data;

    const barreIndexes = findBarre([string1Finger, string2Finger, string3Finger, string4Finger, string5Finger, string6Finger]);

    const displayText = useRef(shortName);
    const displayLongText = useRef(fullName);
    const setText = useRef(shortName);
    const setLongText = useRef(fullName);
    const liRef = useRef(null);

    useEffect(() => {
        updateDOM();
    });

    const updateDOM = () => {
        if (liRef.current) {
            liRef.current.innerHTML = setText.current;
        }
    };

    let style = {};
    const searchFor = data.search || '';
    const searchForRE = new RegExp(searchFor, 'gi');

    if (searchFor !== '') {
        if (searchForRE.test(displayText.current) || searchForRE.test(displayLongText.current)) {
            setText.current = displayText.current.replace(searchForRE, "<mark>$&</mark>");
            setLongText.current = displayLongText.current.replace(searchForRE, "<mark>$&</mark>");
        } else {
            style = { display: 'none' };
        }
    } else {
        setText.current = displayText.current;
        setLongText.current = displayLongText.current;
    }

    const thumb = mainPic !== "" ? (
        <figure className="chord-toolbar__picture">
            <img src={mainPic} className="chord-toolbar__image" alt=""/>
        </figure>
    ) : null;

    return (
        <div style={style} role="listitem" className={`chord-wrapper w-dyn-item ${large ? "chord-fw-wrapper" : ""}`}>
            <div className="chord-versions">
                <div className="text-block-6">— multiple versions</div>
                <a href="/chord/a" className="chord-versions__link">see all</a>
            </div>
            {!large ? (
                <SoundButton slug={Slug} />
            ) : null}
            {!large ? (
                <div className="chord-toolbar">
                    <div className="chord-toolbar__title-wrapper">
                        <a href={`/chords/chord#${Slug}`} className="chord__title" dangerouslySetInnerHTML={{__html: setText.current}}></a>
                        <a href={`/chords/chord#${Slug}`} className="chord-toolbar__subtitle" dangerouslySetInnerHTML={{__html: setLongText.current}}></a>
                    </div>
                    {thumb}
                    <div className="chord-overlay"></div>
                </div>
            ) : null}
            <div className="hand-popover border-radius">
                {handPopoverFinger}
            </div>
            <div className={`chord ${large ? "section-fw-chord" : ""}`}>
                <div className="chord__visual">
                    <div className="chord__neck">
                        <ChotdPosition frets={[string1Fret, string2Fret, string3Fret, string4Fret, string5Fret, string6Fret]} />
                        <div className="chord__strings">
                            <ChordFinger index="6" finger={string6Finger} fret={string6Fret} barre={`${barreIndexes.includes(5)}`} key="6" />
                            <ChordFinger index="5" finger={string5Finger} fret={string5Fret} barre={`${barreIndexes.includes(4)}`} key="5" />
                            <ChordFinger index="4" finger={string4Finger} fret={string4Fret} barre={`${barreIndexes.includes(3)}`} key="4" />
                            <ChordFinger index="3" finger={string3Finger} fret={string3Fret} barre={`${barreIndexes.includes(2)}`} key="3" />
                            <ChordFinger index="2" finger={string2Finger} fret={string2Fret} barre={`${barreIndexes.includes(1)}`} key="2" />
                            <ChordFinger index="1" finger={string1Finger} fret={string1Fret} barre={`${barreIndexes.includes(0)}`} key="1" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const ChordCardSimple = ({data, large = false}) => {
    let frets = [];
    const fingers = data.map((obj, index) => {
        frets.push(obj.fret);
        return (
            <ChordFinger index={index + 1} finger={obj.finger || ""} fret={obj.fret} key={`finger-${index}`} />
        );
    });
    return (
        <div role="listitem" className={`chord-wrapper`}>
            <div className={`chord ${large ? "section-fw-chord" : ""}`}>
                <div className="chord__visual">
                    <div className="chord__neck">
                        <ChotdPosition frets={frets} />
                        <div className="chord__strings">
                            {fingers}
                        </div>
                    </div>
                </div>
            </div>
            <div className="hand-popover border-radius">
                {handPopoverFinger}
            </div>
        </div>
    );
}

export default ChordCard;