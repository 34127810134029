import React from 'react';
import "./styles.scss";

const initSound = (button, chordId) => {
    function playPause(soundId){
      const sound = document.getElementById(soundId);
      
       if (sound.paused && sound.currentTime >= 0 && !sound.ended) {
         sound.play();
       }
    }
 
    function progress(button, soundId, chordId){
       const width = document.getElementById(`sound-btn-${chordId}`).parentNode.offsetWidth;
       const sound = document.getElementById(soundId);
 
       setTimeout(function(){
          let end = sound.duration;
          let current = sound.currentTime;
          let percent = current/(end/100)/100;

          if(current === end){
            sound.currentTime = 0;
            button.style.boxShadow = `rgb(180, 180, 180, 0) ${width}px 0px 0px 0px inset`
             return;
          }
          
          button.style.boxShadow = `rgb(180, 180, 180, ${percent/2}) ${width * 1.2 * percent}px 0px 1px 0px inset`;
          progress(button, soundId, chordId);
       }, 50);
    }
 
    const playSound = (button, chordId) => {
       const soundElement =
       `
          <audio controls preload="auto" id="sound-${chordId}" style="visibility: hidden; height: 0; width: 0; position: absolute;">
             <source src="https://allchords.thestep.agency/sounds/${chordId}.mp3" controls></source
          </audio>
       `
       document.querySelector("body").insertAdjacentHTML("beforeend", soundElement);
       const soundId = `sound-${chordId}`;
       playPause(soundId);
       progress(button, soundId, chordId);
    };

    playSound(button, chordId)
 };

const SoundButton = ({slug, mod=false}) => {
    const playSoundClick = e => {
         const button = e.target.closest(".material-icon--sound");
         const chordId = button.dataset.sound;
         initSound(button, chordId);
    };

    return (
        <div className={`sound-widget ${mod ? "sound-widget--" + mod : ""}`}>
            <div className="chord-sound-wrapper">
                <div className="chord-sound">
                    <svg id={`sound-btn-${slug}`} data-sound={slug} className="material-icon--sound" onClick={playSoundClick} role="img">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-play"></use>
                    </svg>
                </div>
            </div>
            <svg className="sound-wave-icon" role="img">
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#sound-wave"></use>
            </svg>
        </div>
    )
}

export default SoundButton;