import React from 'react';


const Stem = () => <rect className="note__stem" x="24" width="3" height="66" fill="currentColor"/>;
const Head = ({filled = false}) => {
    if (filled) return (<path className="note__head" fillRule="evenodd" clipRule="evenodd" d="M18.5209 78.059C25.0389 74.6366 28.3455 68.0965 25.9064 63.4512C23.4674 58.8059 16.2062 57.8145 9.68822 61.2368C3.17021 64.6592 -0.136412 71.1993 2.30266 75.8446C4.74174 80.4899 12.0029 81.4813 18.5209 78.059ZM16.0093 73.2753C21.2337 70.5322 24.6162 66.6843 23.5643 64.6809C22.5124 62.6775 17.4244 63.2772 12.2 66.0204C6.97556 68.7635 3.59307 72.6114 4.64498 74.6147C5.69688 76.6181 10.7848 76.0185 16.0093 73.2753Z" fill="currentColor"/>);
    return (<ellipse className="note__head-filled" cx="16" cy="65" rx="13.3298" ry="9.5" transform="rotate(-27.7023 23.2182 69.6078)" fill="currentColor"/>);
};
const Flag = ({double = false}) => {
    if (!double) return (<path className="note__flag" d="M34.5 25.5001C31.3 23.1001 27.5 22.1668 26 22.0001C26.1667 12.5001 26.5 0.000120514 26.5 0.000120514C26.5 6.50012 28.5 11.0001 35.5 19.0001C42.5 27.0001 46 33.0001 46 42.0001C46 49.2001 41 57.3335 38.5 60.5001L37.5 59.5001C39.3333 56.0001 43 47.6001 43 42.0001C43 35.0001 38.5 28.5001 34.5 25.5001Z" fill="currentColor"/>);
    return (<path className="note__head-flag-double" fillRule="evenodd" clipRule="evenodd" d="M44.0142 40.3449C44.6319 38.4292 45 36.0889 45 33C45 24 41.4013 19.1112 33.5 12C28.8105 7.77944 26.76 5.75804 26.5234 0.160542C26.5104 -0.595008 26.5 -1 26.5 -1C26.5 -0.596358 26.5077 -0.210069 26.5234 0.160542C26.5606 2.32287 26.6191 7.35647 26.5167 13.9854C26.5054 13.6669 26.5 13.3389 26.5 13C26.5 13 26.1667 22.5 26 32C27.5 32.1667 31.3 34.1 34.5 36.5C34.8537 36.7653 35.223 37.0345 35.6024 37.311C39.5142 40.162 44.5 43.7958 44.5 52C44.5 57.6 43.3333 60 41.5 63.5L42.5 64.5C45 61.3333 47 58.2 47 51C47 46.6054 45.9271 43.403 44.0142 40.3449ZM26.5764 15.0098C26.9997 20.1156 29.2689 22.8788 35.5 30C36.239 30.8446 36.9501 31.6445 37.631 32.4105C39.3909 34.3903 40.9489 36.1429 42.2644 37.8514C42.4184 36.7384 42.5 35.4811 42.5 34C42.5 25.3981 36.5623 21.0211 34.7274 19.6685C34.6425 19.6059 34.5663 19.5498 34.5 19.5C31.3533 17.1399 28.1098 15.2311 26.5764 15.0098Z" fill="currentColor"/>);
};
const NoteChar = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 80" preserveAspectRatio="none" fill="none">
            {props.children}
        </svg>
    )
};

export const NotesGroup = ({children}) => {
    return (
        <div className="notes-group">
            {children}
        </div>
    );
};

const extender = (extend) => {
    if (extend === 1) {
        return (
            <circle xmlns="http://www.w3.org/2000/svg" cx="34" cy="70" r="4" fill="currentColor"/>
        )
    } else if (extend === 2) {
        return (
            <g>
                <circle xmlns="http://www.w3.org/2000/svg" cx="34" cy="70" r="4" fill="currentColor"/>
                <circle xmlns="http://www.w3.org/2000/svg" cx="46" cy="70" r="4" fill="currentColor"/>
            </g>
        )
    }
    return null
}

const NoteFull = ({extend}) => {
    return (
        <NoteChar>
            <Head filled />
            {extender(extend)}
        </NoteChar>
    )
};
const NoteHalf = ({extend}) => {
    return (
        <NoteChar>
            <Head filled />
            <Stem />
            {extender(extend)}
        </NoteChar>
    )
};
const NoteQuart = ({extend}) => {
    return (
        <NoteChar>
            <Head />
            <Stem />
            {extender(extend)}
        </NoteChar>
    )
};
const NoteEight = ({extend}) => {
    return (
        <NoteChar>
            <Head />
            <Stem />
            <Flag />
            {extender(extend)}
        </NoteChar>
    )
};
const NoteSixteenth = ({extend}) => {
    return (
        <NoteChar>
            <Head />
            <Stem />
            <Flag double />
            {extender(extend)}
        </NoteChar>
    )
};

const PauseFull = () => {
    return (
        <NoteChar>
            <rect xmlns="http://www.w3.org/2000/svg" x="0" y="19" width="50" height="1" fill="currentColor"/>
            <rect xmlns="http://www.w3.org/2000/svg" x="7" y="20" width="36" height="9" fill="currentColor"/>
        </NoteChar>
    )
};

const PauseHalf = () => {
    return (
        <NoteChar>
            <rect xmlns="http://www.w3.org/2000/svg" x="0" y="39" width="50" height="1" fill="currentColor"/>
            <rect xmlns="http://www.w3.org/2000/svg" x="7" y="30" width="36" height="9" fill="currentColor"/>
        </NoteChar>
    )
};

const PauseQuart = () => {
    return (
        <NoteChar>
            <path xmlns="http://www.w3.org/2000/svg" 
                d="M22.75 13L34 26.6887C31.6562 29.4499 29.8516 31.8366 28.5859 33.849C27.3203 35.8614 26.6875 37.8971 26.6875 39.9563C26.6875 41.6879 27.25 43.513 28.375 45.4318C29.5 47.3038 31.2813 49.6905 33.7188 52.5921L32.5937 54.1364C30.5313 52.826 28.6094 52.1709 26.8281 52.1709C25.5156 52.1709 24.5312 52.6155 23.875 53.5046C23.2188 54.347 22.8906 55.3766 22.8906 56.5934C22.8906 58.091 23.1953 59.5183 23.8047 60.8755C24.4609 62.1859 25.3281 63.4495 26.4063 64.6662L25.4922 66C22.2578 63.66 19.8672 61.5307 18.3203 59.6119C16.7734 57.6932 16 55.6106 16 53.3642C16 51.3519 16.6328 49.8777 17.8984 48.9417C19.1641 48.0057 20.6875 47.5377 22.4688 47.5377C23.875 47.5377 25.7031 48.0759 27.9531 49.1523V49.0119L17.125 34.6212C21.8594 30.4561 24.2266 26.5717 24.2266 22.9682C24.2266 20.1135 22.7266 16.7907 19.7266 13H22.75Z" 
                fill="currentColor"/>
        </NoteChar>
    )
};

const PauseEight = () => {
    return (
        <NoteChar>
            <path xmlns="http://www.w3.org/2000/svg" 
                d="M23.0153 60L30.3435 34.1865C29.2748 35.1482 28.0534 35.8315 26.6794 36.2364C25.3562 36.6414 24.0331 36.8438 22.7099 36.8438C20.5216 36.8438 18.6896 36.2618 17.2137 35.0976C15.7379 33.9335 15 32.2632 15 30.0868C15 28.6696 15.5089 27.4801 16.5267 26.5184C17.5445 25.5061 18.8422 25 20.4198 25C21.8957 25 23.0916 25.4808 24.0076 26.4425C24.9746 27.3536 25.458 28.5936 25.458 30.1627C25.458 31.2762 25.2036 32.2632 24.6947 33.1236C24.6947 33.4273 25 33.5792 25.6107 33.5792C28.4097 33.5792 31.0814 31.4027 33.626 27.0499H35L25.7634 60H23.0153Z" 
                fill="currentColor"/>
        </NoteChar>
    )
};

const PauseSixteenth = () => {
    return (
        <NoteChar>
            <path xmlns="http://www.w3.org/2000/svg" 
                d="M20.2727 80L27.8364 53.4752C26.7333 54.4634 25.4727 55.1655 24.0545 55.5816C22.6889 55.9976 21.3232 56.2057 19.9576 56.2057C17.699 56.2057 15.8081 55.6076 14.2848 54.4113C12.7616 53.2151 12 51.4988 12 49.2624C12 47.8061 12.499 46.5839 13.497 45.5957C14.5475 44.5556 15.9131 44.0355 17.5939 44.0355C19.1172 44.0355 20.3515 44.5295 21.297 45.5177C22.2949 46.4539 22.7939 47.7281 22.7939 49.3404C22.7939 50.4846 22.5313 51.4988 22.0061 52.383C22.0061 52.695 22.3212 52.8511 22.9515 52.8511C25.1051 52.8511 27.1535 51.6548 29.097 49.2624L33.1939 34.4397C32.0909 35.4279 30.8303 36.13 29.4121 36.5461C28.0465 36.9622 26.6808 37.1702 25.3152 37.1702C23.0566 37.1702 21.1657 36.5721 19.6424 35.3759C18.1192 34.1797 17.3576 32.4634 17.3576 30.2269C17.3576 28.7707 17.8828 27.5485 18.9333 26.5603C19.9838 25.5201 21.3232 25 22.9515 25C24.5273 25 25.7879 25.4941 26.7333 26.4823C27.6788 27.4704 28.1515 28.7447 28.1515 30.305C28.1515 30.8251 28.0727 31.3712 27.9152 31.9433C27.7576 32.4634 27.5737 32.9314 27.3636 33.3475C27.3636 33.6596 27.6788 33.8156 28.3091 33.8156C31.198 33.8156 33.9556 31.5792 36.5818 27.1064H38L23.1091 80H20.2727Z" 
                fill="currentColor"/>
        </NoteChar>
    )
};

export const Note = ({ 
    colored = false, 
    // noteChar = "half", 
    note = "fa", 
    titled = false,
    shift = false,
    length = "half",
    repeat,
    extend=false }) => {

    const noteShort = 
        note === "do" ? "C" :
        note === "re" ? "D" :
        note === "mi" ? "E" :
        note === "fa" ? "F" :
        note === "sol" ? "G" :
        note === "la" ? "A" : 
        note === "si" ? "B" : 
        note === "rest" ? "R" : "C";
    
    const noteCharacter = 
        length === "full" ? note === "pause" ? <PauseFull /> : <NoteFull extend={extend} /> :
        length === "half" ? note === "pause" ? <PauseHalf /> : <NoteHalf extend={extend} /> :
        length === "quart" ? note === "pause" ? <PauseQuart /> : <NoteQuart extend={extend} /> :
        length === "eight" ? note === "pause" ? <PauseEight /> : <NoteEight extend={extend} /> :
        length === "sixteenth" ? note === "pause" ? <PauseSixteenth /> : <NoteSixteenth extend={extend} /> : <NoteHalf extend={extend} />;
    
    const addTitle = () => {
        if (titled && length.length > 2) {
            return (
                <div className="notes-grid__note-name">
                    {noteShort} <br />
                    <span className="text-span-12">{note}</span>
                </div>
            )
        }
        return null;
    }
    
    const classes = 
        length.length <= 2 ? 
        "note" : `note ${ note !== "pause" ? "note--" + noteShort.toLocaleLowerCase() : "note--pause"} ${shift ? "note--" + noteShort.toLocaleLowerCase() + "-" + shift : ""}`;

    return (
        <div className={`${classes} ${colored ? typeof colored == "boolean" ? "note--colored" : `note--colored note--colored-${colored}` : ""}  note--${length}`}>
            <div className="note__character">
                {noteCharacter}
            </div>
            { addTitle() }
        </div>
    );
};

export const NotesBar = ({nums = [], mod=false, comment=false, children}) => {
    const notes = nums.map(obj => {
        return (
            <Note {...obj}/>
        );
    });

    let classes = mod ? 
        Array.isArray(mod) ? mod.map(m => `notes-grid__tabs-bar--${m}`).join(" ") : `notes-grid__tabs-bar--${mod}` : "";

    if (children) {
        return (
        <div className={`notes-grid__tabs-bar ${classes} ${comment ? `comment comment--${comment[1]}` : ""}`} 
            comment={comment[0]}>
            {children}
        </div>
        )
    };
    return (
        <div className={`notes-grid__tabs-bar ${classes} ${comment ? `comment comment--${comment[1]}` : ""}`} 
            comment={comment[0]}>
            {notes}
        </div>
    );
};

const NotesStave = ({
        nums = [], 
        numTopColor = false,
        numBottomColor = false,
        noteKey="𝄞", 
        noteKeyColored=false, 
        children, 
        extraPaddingTop = false, 
        extraPaddingBottom = false
    }) => {
    const noteNums = nums => {
        const topColorClass = numTopColor ? ` notes-grid__num--${numTopColor}` : "";
        const bottomColorClass = numBottomColor ? ` notes-grid__num--${numBottomColor}` : "";
        if (nums.length > 0) {
            return (
                <div className="notes-grid__nums">
                    <div className={`notes-grid__num${topColorClass} top`}>{nums[0]}</div>
                    <div className={`notes-grid__num${bottomColorClass} ${nums[1] === 16 ? "notes-grid__num--" + nums[1] : ""} bottom`}>
                        {nums[1]}
                    </div>
                </div>
            );
        }
        return null;
    };

    const noteKeySymbol = 
        noteKey === null 
        ? null 
        : <div className={`notes-grid__key ${noteKeyColored ? "notes-grid__key--colored-" + noteKeyColored : ""}`}>
                {noteKey}
          </div>;
    
    const paddingTop = extraPaddingTop ? " padding-top--" + extraPaddingTop : "";
    
    const paddingBottom = extraPaddingBottom ? " padding-bottom--" + extraPaddingBottom : "";

    return (
        <div className="section__overflow">
            <div className="section__scroll">
                <div className="notes-grid">
                    <div className={`tabs__row${paddingTop}${paddingBottom}`}>
                        <div className="notes-grid__stave">
                            {noteKeySymbol}
                            {noteNums(nums)}
                            {children}
                            <div className="tabs-nums">
                                <div className="notes-grid__num-char _1">1</div>
                                <div className="notes-grid__num-char _2">2</div>
                                <div className="notes-grid__num-char _3">3</div>
                                <div className="notes-grid__num-char _4">4</div>
                                <div className="notes-grid__num-char">5</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotesStave;