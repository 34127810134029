import React from "react";

export const romanize = num => {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
               "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
               "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
};

export const elMultiplier = (component, num) => {

    let components = [];
    for(let i = 1; i <= num; i++) {
        components.push(React.cloneElement(component, { key: i }));
    }
    return components;
}