import React, { useEffect } from "react";
import "./styles.scss";

export const HeroHeadline = ({children}) => {
    return (
        <h1 className="hero-headline">
            {children}
        </h1>
    )
}

export const HeroSubheadline = ({children}) => {
    return (
        <div className="subtitle-text">
            {children}
        </div>
    )
}

export const HeroSection = ({children = null, imgUrl = "", imgFormat="jpg"}) => {
    useEffect(() => {
        const animateHeroSection = () => {
            console.log("init");
            const distance = (window.innerHeight - window.scrollY) / (window.innerHeight / 22) * 0.01;

            if (distance > 0) {
                document.querySelector(".hero-image").style.opacity = distance;
                document.querySelector(".hero-image").style.transform = `translate(-50%, ${100 * distance}px)`;
            } else {
                document.querySelector(".hero-image").style.opacity = "0";
                document.querySelector(".hero-image").style.transform = `translate(-50%, 100px)`;
            };
        };
        animateHeroSection();
        if (window.scrollY < 30) window.scrollTo(0, 0);
        window.addEventListener("scroll", animateHeroSection);       
    });

    return (
        <section className="section section--fh">
            <div className="container">
                <div className="hero-headline-wrapper">
                    {children}
                </div>
            </div>
            <img 
                className="hero-image" 
                src={`${imgUrl}_1280.${imgFormat}`} 
                loading="lazy" 
                sizes="100vw" 
                srcSet={`${imgUrl}_1280-p-500.${imgFormat} 500w, ${imgUrl}_1280-p-800.${imgFormat} 800w, ${imgUrl}_1280-p-1080.${imgFormat} 1080w, ${imgUrl}_1280.${imgFormat} 1280w`}
                alt="" />
        </section>
    )
};