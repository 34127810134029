import { romanize } from '../../scripts/helpers';

export const initChordResize = () => {
    const chordWrapper = document.querySelectorAll(".chord");
    let maxFrets;
    const windowWidth = window.outerWidth;
  
    if (windowWidth < 400) maxFrets = 3;
    else if (windowWidth >= 400 && windowWidth < 500) maxFrets = 4;
    else if (windowWidth > 500) maxFrets = 5;
  
    chordWrapper.forEach((element) => {
      const lgChordOffset = 60;
      const items = element.querySelectorAll(".chord__finger:not(.chord__fret--0, .chord__fret---1)");
      const chord = element.querySelector(".chord__visual");
      const wrapperWidth = element.offsetWidth;
      const offset = wrapperWidth < 500 ? (lgChordOffset * -1) : 0;
  
      let frets = [];
      let offsets = [];
  
      element.style.transform = "";
  
      items.forEach((e) => {
        frets.push(e.getAttribute("fret"));
        offsets.push(e.offsetLeft);
      });
  
      let maxOffset = Math.max(...offsets);
      let minOffset = Math.min(...offsets);
      let maxFret = Math.max(...frets);
      let minFret = Math.min(...frets);
  
      maxOffset = Math.round(maxOffset);
      minOffset = Math.round(minOffset);
      maxFret = Math.round(maxFret);
      minFret = Math.round(minFret);
  
      let position = minFret;
  
      // position = !chord.querySelector(".chord__finger[finger='1']") && minFret > 1 ? position - 1 : position;
  
      if (chord.offsetWidth - minOffset + 50 > wrapperWidth && maxFret > maxFrets) {
        const sideOffset = ((wrapperWidth - (maxOffset - minOffset)) / 2);
        let chordOffset = (minOffset - sideOffset + lgChordOffset) * -1;
        chordOffset = chordOffset < 0 ? chordOffset : 0;
        chord.style.right = "auto";
        chord.style.left = chordOffset + "px";
      } else {
        chord.style.right = offset + "px";
        chord.style.left = "";
      }
  
      const chordPositionClass = "chord__position--" + position;
      const chordPosition = chord.querySelector(".chord__position");
      if (!chordPosition) return;
      chordPosition.classList.add(chordPositionClass);
      chordPosition.textContent = romanize(minFret);
    });
}
  
export const initBarre = () => {
    const chordWrapper = document.querySelectorAll(".chord");
    chordWrapper.forEach(element => {
      const items = Array.from(element.querySelectorAll(".chord__finger[barre='true']"));
      if (items.length < 1) return;
      const barreHeight = 
        items[items.length - 1].getBoundingClientRect().top - items[0].getBoundingClientRect().top;

      const circle = items[0].querySelector(".chord__finger-circle");
      circle.style.height = Math.round(barreHeight + 16) + "px";
      circle.style.top = "-6px";

      for(let i in items) {
        if (i > 0) items[i].remove();
      }
    });
}

export function initFingerHover() {
    let showed = false;

    document.addEventListener("mousemove", e => {
        const circle = e.target;

        const containClass = (className) => {
            return e.target.classList.contains(className)};

        const cursorOverCircle = 
            containClass("chord__finger-circle") &&
            !containClass("chord__finger-circle--0") &&
            !containClass("chord__finger-circle---1");

        if(!cursorOverCircle && !showed || cursorOverCircle && showed) return;

        showed = true;

        const wrapper = circle.closest(".chord-wrapper");
        const popover = wrapper.querySelector(".hand-popover");
        const finger = circle.closest(".chord__finger");
        const number = finger ? finger.getAttribute("finger") : false;

        circle.addEventListener("mouseleave", () => {
            popover.style.display = "none";
            popover.querySelectorAll(".hand-popover__finger").forEach((finger) => {
                finger.classList.remove("hand-popover__finger--active");
            });
            circle.closest(".chord__string").style.zIndex = "";
            showed = false;
        });

        if (!number) return;

        popover.style.display = "block";
  
        const popoverWidth = popover.offsetWidth;
        const popoverHeight = popover.offsetHeight;
  
        const positionLeft = 
            finger.getBoundingClientRect().left - wrapper.getBoundingClientRect().left - (popoverWidth / 2 + 8);
        const positionTop = 
            finger.getBoundingClientRect().top - wrapper.getBoundingClientRect().top - (popoverHeight + 20);
  
        popover
            .querySelector(".hand-popover__finger--" + number)
            .classList.add("hand-popover__finger--active");
        
        popover.style.left = positionLeft + "px";
        popover.style.top = positionTop + "px";
        finger.closest(".chord__string").style.zIndex = 1;
    });
}
