import React from 'react';
import "./styles.scss";

export const ToggleButtons = (props) => {
    const mod = props.mod || false;
    return (
        <div className={`toggle-buttons ${mod ? "toggle-buttons--"+mod : ""}`}>
            {props.children}
        </div>
    );
};

export const ToggleButton = ({title, iconId = false, active = false, tabIndex="-1"}) => {
    function click(e){
        e.preventDefault();
        function siblings(elem) {
            return Array.from(elem.parentNode.children).filter(el => el !== elem);
        }
        const target = e.target.closest(".toggle-buttons__item");
        const id = target.getAttribute("id");
        target.blur();
        target.classList.add("is-active");
        siblings(target).map(el => el.classList.remove("is-active"));

        const body = document.querySelector("body");
        
        if (id === "toggle-righty") body.classList.remove("lefty");
        else body.classList.add("lefty");
    }
    const icon = iconId ? (
        <svg className="toggle-buttons__icon" role="img">
            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#${iconId}`}></use>
        </svg>
    ) : null;
    return (
        <div
            id={`toggle-${title.replace(/\s+/g, '-').toLowerCase()}`}
            className={`toggle-buttons__item ${active ? "is-active": ""}`}
            tabIndex={tabIndex}
            onClick={click}>
            {icon}
            <div className="toggle-buttons__text">{title}</div>
        </div>
    );
};