import React from 'react';

const AppHeader = () => {
    return (
        <header className="header">
            <nav className="header__navigation">
                <div className="navigation__item">
                    <a href="/chords" className="navigation__link">Chords</a>
                    <link rel="prefetch" href="/chords"/>
                </div>
                <div className="navigation__item">
                    <a href="/notes" className="navigation__link">Tutorials</a>
                    <link rel="prefetch" href="/notes"/>
                </div>
            </nav>
            <div className="header__logo-row">
                <a href="/" className="main-logo w-inline-block">
                    <svg className="main-logo__img" role="img">
                      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#main-logo"></use>
                    </svg>
                </a>
                <div className="material-icon material-icon--link material-icon--menu">menu</div>
                <div className="material-icon material-icon--link material-icon--menu-close">close</div>
                <div className="material-icon material-icon--link icon-link--settings">tune</div>
            </div>
            <nav className="header__navigation">
                <div className="navigation__item">
                    <a href="/tools" className="navigation__link">Tools<span className="navigation__subline"></span></a>
                    <link rel="prefetch" href="/tools"/>
                </div>
                <div data-w-id="d5844bbf-79bb-7b4c-0da3-833b2dd3af04" className="navigation__item navigation__item--settings">
                    <a href="#" id="settings" className="navigation__link navigation__link--settings">Settings<span className="navigation__subline"></span></a>
                </div>
            </nav>
        </header>
    );
}

export default AppHeader;