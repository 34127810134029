import React from 'react';
import "./styles.scss";

const PianoKey = ({note, diez = true, diezLabeled = false}) => {
    const noteShort = 
        note === "do" ? "C" :
        note === "re" ? "D" :
        note === "mi" ? "E" :
        note === "fa" ? "F" :
        note === "sol" ? "G" :
        note === "la" ? "A" : 
        note === "si" ? "B" : 
        note === "rest" ? "R" : "C";
    
    const diezLabel = diezLabeled ? (
        <div className={"piano-note piano-note--diez piano-note--" + noteShort.toLowerCase()}>
            {noteShort}<span className="diez-symbol">&#x266F;</span>
        </div>
    ) : null;
    
    const diezKey = diez ? (
        <div className="piano-key-black">
            <div className="piano-key-black__light"></div>
            {diezLabel}
        </div>
    ) : null;

    return (
        <div className="piano-key-white">
            {diezKey}
            <div className={"piano-note piano-note--" + noteShort.toLowerCase()}>
                {noteShort}
            </div>
            <div className="xxx-copy-copy">{note}</div>
        </div>
    )
}

export const PianoKeysOctave = ({diezLabeled=false}) => {
    return (
        <div>
            <PianoKey note="si" diez={false} diezLabeled={false}/>
            <PianoKey note="la" diezLabeled={diezLabeled}/>
            <PianoKey note="sol" diezLabeled={diezLabeled}/>
            <PianoKey note="fa" diezLabeled={diezLabeled}/>
            <PianoKey note="mi" diez={false} diezLabeled={false}/>
            <PianoKey note="re" diezLabeled={diezLabeled}/>
            <PianoKey note="do" diezLabeled={diezLabeled}/>
        </div>
    )
}

export const PianoKeys = ({children}) => {
    return (
        <div className="div-block-4">
            {children}
        </div>
    )
}