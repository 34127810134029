import React, {useState} from 'react';
import ChordCard from "../chord-card";
import * as chordsData from '../chord-card/chords-list.json';
import SettingsToolbar from "../settings-toolbar";

const ChordsGrid = () => {
    const [value, setValue] = useState('');

    const onChange = (e) => {
        let val = e.target.value.replace("#", "⌗");
        setValue(val);
    };

    const chordsList =  JSON.parse(JSON.stringify(chordsData));

    const ChordsCards = Array.from(chordsList).map(chordData => {
        chordData.search = value;
        return (
            <ChordCard data={chordData} key={chordData["Item ID"]} />
        )
    });

    return (
        <div className="chords-grid-wrapper">
            <SettingsToolbar value={value} onChange={onChange} />
            <div className="chords-grid w-dyn-items" role="list">
                { ChordsCards }
            </div>
        </div>
    );
}

export default ChordsGrid;