import React from 'react';
import "./styles.scss";

const NoteScheme = () => {
    const style = {"whiteSpace": "pre"};
    return (
        <div>
            <svg className="note-scheme" preserveAspectRatio="none" viewBox="0 0 541 355" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g className="note-scheme__flag">
                    <text style={style}>
                        <tspan textAnchor="end" x="470" y="170.243">Флаг</tspan>
                    </text>
                    <path d="M295.15 124.314C282.67 114.873 267.85 111.201 262 110.546C262.65 73.174 263.95 24.0005 263.95 24.0005C263.95 49.5707 271.75 67.2731 299.05 98.7441C326.35 130.215 340 153.818 340 189.223C340 217.547 320.5 249.543 310.75 262L306.85 258.066C314 244.298 328.3 211.253 328.3 189.223C328.3 161.686 310.75 136.116 295.15 124.314Z" fill="url(#Gradient2)" stroke="none"/>
                    <line x1="349" y1="179.5" x2="471" y2="179.5"/>
                </g>
                <g className="note-scheme__head">
                    <text style={style}>
                        <tspan x="39" y="296.243">Головка</tspan>
                    </text>
                    <ellipse cx="214.5" cy="296.7" rx="52.25" ry="37.241" transform="rotate(-27.7023 214.577 297.721)"/>
                    <line x1="39" y1="305.5" x2="154" y2="305.5"/>
                </g>
                <g className="note-scheme__steam">
                    <text style={style}>
                        <tspan x="39" y="170.243">Штиль</tspan>
                    </text>
                    <rect x="252" y="24" width="12" height="259" fill="url(#Gradient1)" stroke="none"/>
                    <line x1="39" y1="179.5" x2="242" y2="179.5"/>
                </g>
                <defs>
                    <linearGradient id="Gradient1" x1="0" x2="0" y1="0" y2="1">
                        <stop className="stop1" offset="0%" />
                        <stop className="stop2" offset="60%" />
                        <stop className="stop3" offset="100%" />
                    </linearGradient>
                    <linearGradient id="Gradient2">
                        <stop className="stop1" offset="0%" />
                        <stop className="stop2" offset="100%" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default NoteScheme;