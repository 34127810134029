import React from "react";
import "./styles.scss";
import { Note } from "../notes-stave/notes-stave";

export const NoteBrick = ({length = "half", pause = false, extend=false, greyscale=false}) => {
    const extendedClass = extend ? `note-grid__brick--extended-${extend}` : "";
    const greyscaleClass = greyscale ? "note-grid__brick--greyscale" : "";
    return (
        <div className={`note-grid__brick note-grid__brick--${length} ${extendedClass} ${greyscaleClass}`}>
            <Note length={length} extend={extend} note={`${pause ? "pause" : false}`} />
        </div>
    );
}

export const NotesLengthGrid = ({children}) => {
    return (
        <div className="notes-grid__section">
            {children}
        </div>
    );
}

export const NotesLengthGridRow = ({children, comment=false}) => {
    return (
        <div className="tabs__row" comment={comment}>
            {children}
        </div>
    );
}